/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
import '../sass/nonproduct-styling.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

$(window).on("load", function() {
    //Homepage graphics being adjusted
    $(".graph-homepage-container:not(.is__static) .stripe_text").width(($(".homepage-bar").width()*3) + (parseInt($(".homepage-bar").css('margin-right'))*2)  + 90);
    $(".graph-homepage-line").width(($(".homepage-bar").width()*3) + (parseInt($(".homepage-bar").css('margin-right'))*2)  + 98);
    $(".graph-homepage-container:not(.is__static) .first_percentage").width(($(".homepage-bar").width()*3) + (parseInt($(".homepage-bar").css('margin-right'))*2)  + 68);
    $(".second_percentage").width(($(".homepage-bar").width()*2) + (parseInt($(".homepage-bar").css('margin-right')))  + 68);
    $(".third_percentage").width(($(".homepage-bar").width())  + 68);

    setTimeout(function(){
        $("#donate-container").css({
            "visibility" : "visible",
            "display" : "none"
        });
    }, 200);

    if($(".stripe").length)
    {
        var card = elements.create("card");
        card.mount("#card-element");

        $("#card-donate-submit:not([disabled])").on("click", function(e){
            e.preventDefault();
            $(this).attr("disabled");

            var amount = $("#donation-amount").val().replace(/[^a-zA-Z0-9 ]/g, '');
            var email = $("#donation-email").val();

            $.ajax({
                url: '/p/secret',
                data : {"donation-amount" : amount, "donation-email": email},
                method: 'POST',
                success: function(data) {
                    var clientSecret = data;

                    stripe.confirmCardPayment(clientSecret, {
                        payment_method: {
                            card: card,
                            billing_details: {
                                email : email
                            }
                        }
                    }).then(function(result) {
                        if (result.error) {
                            // Show error to your customer (e.g., insufficient funds)
                            console.log(result.error.message);
                        }
                        else {
                            // The payment has been processed!
                            if (result.paymentIntent.status === 'succeeded') {
                                $.ajax({
                                    url : '/p/confirm-donation',
                                    data : {"email" : email, "amount": $("#donation-amount").val()},
                                    method : 'POST',
                                    success : function(data){
                                        if(data == "success")
                                        {
                                            card.clear();
                                            $("#donation-amount").val("$0.00");
                                            $("#donation-email").val("");
                                            $("#donate-message").text("Thank you for donating! We greatly appreciate it!");
                                        }
                                    }
                                })
                            }
                        }
                    });
                }
            });
        });
    }

    if($(".subnav").length)
    {
        if (typeof($(".subnav").attr('pos') ) == 'undefined' || $(".subnav").attr("pos") == false)
        {
            $(".subnav").attr("pos", $(".subnav").offset().top)
        }
    }
});

$(window).scroll(function(){
    var scrollTop = $(window).scrollTop();

    if($(".graph-homepage-container:not(.is__static)").length)
    {
        if(scrollTop + 300 > $(".graph-homepage-container:not(.is__static)").offset().top)
        {
            $(".graph-homepage-container:not(.is__static)").addClass("animate");
        }
    }

    if($(".subnav").length)
    {
        if (typeof($(".subnav").attr('pos') ) == 'undefined' || $(".subnav").attr("pos") == false)
        {
            $(".subnav").attr("pos", $(".subnav").offset().top)
        }

        if(scrollTop > $(".subnav").attr("pos"))
        {
            $("header").css({
                "margin-bottom" : $(".subnav").outerHeight()
            })
            $(".subnav").addClass("fixed-top");
        }
        else if(scrollTop <= $(".subnav").attr("pos"))
        {
            $(".subnav").removeClass("fixed-top");
            $("header").removeAttr("style");
        }
    }
});

$(window).resize(function(){
    //Homepage graphics being adjusted
    $(".graph-homepage-container:not(.is__static) .stripe_text").width(($(".homepage-bar").width()*3) + (parseInt($(".homepage-bar").css('margin-right'))*2)  + 90);
    $(".graph-homepage-line").width(($(".homepage-bar").width()*3) + (parseInt($(".homepage-bar").css('margin-right'))*2)  + 98);
    $(".graph-homepage-container:not(.is__static) .first_percentage").width(($(".homepage-bar").width()*3) + (parseInt($(".homepage-bar").css('margin-right'))*2)  + 68);
    $(".second_percentage").width(($(".homepage-bar").width()*2) + (parseInt($(".homepage-bar").css('margin-right')))  + 68);
    $(".third_percentage").width(($(".homepage-bar").width())  + 68);
});
